import {Component, Inject, NgZone, OnInit} from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {EventStatus, HTTPStatus} from './interceptors';
import {UserService} from './services/user/user.service';
import {UtilityService} from './services/utility';
import {NavigationCancel, NavigationEnd, Router} from '@angular/router';
import {debounceTime, filter, take, tap} from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
declare var window: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  private cookiesEnabled: boolean=true;

  public HTTPActivity: boolean;
  handlerMessage = '';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private httpStatus: HTTPStatus,
    private ngZone: NgZone,
    private router: Router,
    private event: EventStatus,
    private userService: UserService,
    private util: UtilityService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.cookiesEnabled=this.areCookiesEnabled();
    this.userService.hasCookiesEnabled.next(this.cookiesEnabled);

    this.initializeApp();
    this.settingUpToast();
    this.settingUpLoaderForEveryRequest();

  }
  
  areCookiesEnabled() {
    try {
      this._document.cookie = 'cookietest=1';
      var cookiesEnabled = this._document.cookie.indexOf('cookietest=') !== -1;
      this._document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
      return cookiesEnabled;
    } catch (e) {
      return false;
    }
  }

  ngOnInit(): void {
  }

  settingUpLoaderForEveryRequest() {
    this.httpStatus.getHttpStatus().pipe(debounceTime(600))
        .subscribe(async (status: boolean) => {
          console.log(status);
          this.HTTPActivity = status;
          this.ngZone.run(() => {
            this.HTTPActivity = status;
          });
        });
  }
  settingUpToast() {
    this.event.getEvent().subscribe((event: any) => {
      console.log(event);
      if (event.status === 401 || event.status === 403) {
        this.userService.clearAll();
        this.util.presentToast('Your session expired, please login again!', 5000);
        this.router.navigate(['/login']);
        return false;
      } else if (event.status >= 400) {
        console.log(event);
        this.util.presentToast(event.error.message, 5000);
      }
    });
  }
  initializeApp() {
    this.platform.ready().then(() => {
      console.log('APP READY');
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    window.addEventListener('load',function() {
      setTimeout(() => {
        // This hides the address bar:
        window.scrollTo(0, 1);
      }, 0);
    });
  }
}
