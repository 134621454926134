// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Test comment
export const environment = {
  production: true,
  server: 'https://api.kickpredict.co',
  square_appId : 'sq0idp-qOhkV03m2KZqD_7aRpsTLg',
  square_locationId: 'LWA797NHJNGCH',
  PAYMENT_API: 'https://apikickpredict.techup.me/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
