import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './guard/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tabs/tabs/home',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'landing-page',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/auth/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'forgot-password',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/home/about/about.module').then( m => m.AboutModule)
  },
  {
    path: 'bids',
    loadChildren: () => import('./pages/bids/bids.module').then( m => m.BidsPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'billing-address',
    loadChildren: () => import('./pages/profile/billing-address/billing-address.module').then( m => m.BillingAddressPageModule)
  },
  {
    path: 'paypal',
    loadChildren: () => import('./pages/paypal/paypal.module').then( m => m.PaypalPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'ref',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/ref/ref.module').then( m => m.RefPageModule)
  },
  {
    path: 'crypto-payment',
    loadChildren: () => import('./pages/crypto-payment/crypto-payment.module').then( m => m.CryptoPaymentPageModule)
  },
  {
    path: 'payment-status',
    loadChildren: () => import('./pages/payment-status/payment-status.module').then( m => m.PaymentStatusPageModule)
  },  {
    path: 'square-payment',
    loadChildren: () => import('./pages/square-payment/square-payment.module').then( m => m.SquarePaymentPageModule)
  }



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
